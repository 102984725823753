import { useCounter } from "react-use";

export default function useShowMoreCounter(total: number, limit: number) {
  const [count, { inc: increment, dec: decrement }] = useCounter(1);
  const offset = Math.min(count * limit, total);

  return {
    offset,
    hasMore: count * limit < total,
    hasLess: count > 1,
    showMore: () => increment(1),
    showLess: () => decrement(1),
  };
}
