import * as palette from "@govlaunch/palette";
import React, { ReactElement } from "react";
import Dotdotdot from "~/components/text/Dotdotdot";
import ProgressiveImage from "react-progressive-image";
import { Margin } from "~/components/spacings";
import useIsPhone from "~/lib/hooks/useIsPhone";
import { Anchor, getSecureContent } from "@govlaunch/web";

interface IRelatedUrlCardProps {
  url: string;
  favicon: string;
  siteName: string;
  title: string;
  description: string;
  onRemove?: (relatedUrl: string) => void;
  [key: string]: any;
}

export function RelatedUrlCard({
  url,
  favicon,
  siteName,
  title,
  description,
  onRemove,
  ...props
}: IRelatedUrlCardProps): ReactElement {
  const isPhone = useIsPhone();

  return (
    <div
      {...props}
      css={{
        borderRadius: 20,
        border: `solid 1px ${palette.lightestGray}`,
        backgroundColor: "#ffffff",
        padding: "17px 20px",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        height: "100%",
        minHeight: 131,
      }}
    >
      {onRemove && (
        <button
          type="button"
          onClick={() => onRemove(url)}
          css={{
            color: palette.red,
            fontSize: 28,
            outline: 0,
            border: 0,
            background: "none",
            position: "absolute",
            top: 5,
            right: 6,
            cursor: "pointer",
          }}
        >
          &times;
        </button>
      )}

      <div>
        <Anchor
          href={url}
          external="outside-govlaunch"
          css={{
            fontSize: 16,
            margin: 0,
            fontWeight: 500,
            lineHeight: 1.25,
            paddingRight: 10,
            textDecoration: "none",
            color: palette.text,
            display: "inline-block",
            maxWidth: "100%",
          }}
        >
          <Dotdotdot clamp={isPhone ? 8 : 1}>{title || siteName}</Dotdotdot>
        </Anchor>

        <Margin mt={5}>
          <div
            css={{
              lineHeight: 1.29,
              color: palette.darkGray,
              fontSize: 14,
            }}
          >
            <Dotdotdot clamp={2}>{description}</Dotdotdot>
          </div>
        </Margin>
      </div>

      <div
        css={{
          marginTop: "auto",
        }}
      >
        <Margin
          mt={10}
          css={{
            display: "flex",
            alignItems: "stretch",
          }}
        >
          {favicon && (
            <ProgressiveImage src={favicon} placeholder="">
              {(src: string, loading: boolean) => {
                if (loading || !src) {
                  return null;
                }

                return (
                  <div
                    css={{
                      marginRight: 5,
                      display: "inline-flex",
                    }}
                  >
                    <img
                      src={favicon.startsWith("https") ? favicon : getSecureContent(favicon, "outside-govlaunch")}
                      css={{
                        width: 16,
                        height: 16,
                      }}
                    />
                  </div>
                );
              }}
            </ProgressiveImage>
          )}

          <span
            css={{
              color: palette.darkGray,
              fontSize: 14,
            }}
          >
            {siteName}
          </span>
        </Margin>
      </div>
    </div>
  );
}
