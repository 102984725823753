import { Button, Input, LoadingIndicator } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@govlaunch/web";
import React, { useRef, useState } from "react";
import { Field } from "react-final-form";
import { useIntl } from "react-intl";
import useIsMobile from "~/lib/hooks/useIsMobile";
import useUnfurl, { IUnfurl } from "~/lib/hooks/useUnfurl";
import isValidUrl from "~/lib/utils/isValidUrl";
import prefixWithHttpIfNecessary from "~/lib/utils/prefixWithHttpIfNecessary";
import PlusIcon from "~/components/icons/PlusIcon";
import { RelatedUrlCard } from "~/components/newsworthy/RelatedUrlCard";
import { RelatedUrlCardScrollbars } from "~/components/newsworthy/RelatedUrlCardScrollbars";

interface INewsworthyFieldProps {
  fieldName: string;
  padding?: number;
}

interface INewsworthyItem {
  title: string;
  description: string;
  siteName: string;
  favicon: string;
  url: string;
}

export default function NewsworthyField({ fieldName, padding }: INewsworthyFieldProps) {
  const intl = useIntl();
  const isMobile = useIsMobile();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Field name={fieldName}>
      {({ input }) => (
        <>
          <DuplicatedLinkModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            text={intl.formatMessage({
              defaultMessage: 'This link has already been added, please try a different one!', id: 'HwqbKy',
            })}
            closeButtonText="Close"
          />

          <div>
            <NewsworthyInput
              onChange={(item) => {
                if (input.value && input.value.length > 0) {
                  const duplicatedUrl = input.value.find((newsworthy: INewsworthyItem) => item.url === newsworthy.url);

                  if (duplicatedUrl) {
                    setIsModalOpen(true);
                    return;
                  }

                  input.onChange([item].concat(input.value));
                } else {
                  input.onChange([item]);
                }
              }}
              padding={padding}
            />
          </div>

          {input.value && Array.isArray(input.value) && input.value.length > 0 && (
            <div
              css={{
                paddingRight: 15,
                paddingBottom: 15,
                paddingLeft: 15,
                overflow: "hidden",
              }}
            >
              <RelatedUrlCardScrollbars visible={!isMobile}>
                <div
                  css={{
                    display: "grid",
                    flexWrap: "wrap",
                    gridRowGap: 16,
                    gridColumnGap: isMobile ? undefined : 16,
                    gridTemplateColumns: isMobile ? undefined : `repeat(${input.value.length}, 320px)`,
                    marginBottom: 10,
                  }}
                >
                  {input.value.map(({ url, title, siteName, description, favicon }: INewsworthyItem) => {
                    return (
                      <RelatedUrlCard
                        key={url || ""}
                        url={url || ""}
                        title={title || ""}
                        siteName={siteName || ""}
                        description={description || ""}
                        favicon={favicon || ""}
                        onRemove={() => {
                          input.onChange(
                            input.value.filter(({ url: maybe }: INewsworthyItem) => {
                              return url !== maybe;
                            }),
                          );
                        }}
                        style={{
                          width: isMobile ? "100%" : 320,
                        }}
                      />
                    );
                  })}
                </div>
              </RelatedUrlCardScrollbars>
            </div>
          )}
        </>
      )}
    </Field>
  );
}

interface INewsworthyInputProps {
  onChange: (value: IUnfurl) => any;
  placeholder?: string;
  padding?: number;
}

export const NewsworthyInput: React.FunctionComponent<INewsworthyInputProps> = ({
  onChange,
  placeholder,
  padding = 15,
}) => {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>();
  const intl = useIntl();

  const [unfurling] = useUnfurl(
    url,
    (unfurl) => {
      (inputRef.current as HTMLInputElement).value = "";
      onChange(unfurl);
      setLoading(false);
      setUrl("");
    },
    () => {},
  );

  const addItem = () => {
    const url: string = (inputRef.current as HTMLInputElement).value.trim();
    const prefixedUrl = prefixWithHttpIfNecessary(url);

    if (prefixedUrl && isValidUrl(prefixedUrl)) {
      setLoading(true);
      setUrl(prefixedUrl);
    } else {
      setLoading(false);
    }
  };

  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        padding: padding,
      }}
    >
      <div
        css={{
          position: "relative",
          width: "95%",
        }}
      >
        <Input
          innerRef={inputRef}
          onKeyDown={(event: React.KeyboardEvent) => {
            if (event.key !== "Enter") {
              return;
            }

            event.preventDefault();
            event.stopPropagation();
            addItem();
          }}
          placeholder={placeholder || intl.formatMessage({ defaultMessage: 'Enter a URL...', id: 'f1FNlL' })}
          css={{
            backgroundColor: palette.white,
            border: `solid 1px ${palette.lightestGray}`,
            fontSize: 14,
            height: 42,
            paddingLeft: 20,
            paddingRight: 20,
            letterSpacing: 0.5,
            color: palette.mediumGray,
            lineHeight: 1.14,
            outline: "none",
            borderRadius: 4,
            "&:focus": {
              border: `solid 1px ${palette.primary}`,
            },
          }}
        />

        {(unfurling || loading) && (
          <div
            css={{
              position: "absolute",
              top: "50%",
              right: 20,
              transform: "translateY(-50%)",
            }}
          >
            <LoadingIndicator />
          </div>
        )}
      </div>
      <button
        css={{
          width: "5%",
          height: 42,
          textAlign: "center",
          color: palette.sealBlue,
          fontSize: 13,
          cursor: "pointer",
          marginLeft: 10,
          border: `solid 1px ${palette.lightestGray}`,
          borderRadius: 4,
          backgroundColor: palette.white,
          "&:hover": {
            backgroundColor: palette.lightestGray,
          },
          "&:focus": {
            border: `solid 1px ${palette.primary}`,
          },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        type="button"
        aria-label={intl.formatMessage({
          defaultMessage: 'Add Newsworthy item', id: '+rLLjT',
        })}
        onClick={(event) => {
          event.preventDefault();
          addItem();
        }}
      >
        <PlusIcon />
      </button>
    </div>
  );
};

interface IDuplicatedLinkModalProps {
  isOpen: boolean;
  onClose: () => any;
  text: string;
  closeButtonText: string;
}

export function DuplicatedLinkModal({ isOpen, onClose, text, closeButtonText }: IDuplicatedLinkModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader px={2} />
        <ModalBody textAlign="center">{text}</ModalBody>

        <ModalFooter justifyContent="center">
          <Button variantColor="blue" mr={2} onClick={onClose}>
            {closeButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
