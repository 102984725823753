import React, { ReactElement } from "react";

interface IRelatedUrlCardScrollbarsProps {
  visible?: boolean;
  children: any;
}

export function RelatedUrlCardScrollbars({
  visible = true,
  children,
  ...props
}: IRelatedUrlCardScrollbarsProps): ReactElement {
  if (visible) {
    return (
      <div
        {...props}
        css={{
          display: "flex",
          overflowX: "scroll",
          "::-webkit-scrollbar": {
            height: 8,
            WebkitAppearance: "none",
          },
          "::-webkit-scrollbar-thumb": {
            borderRadius: 4,
            backgroundColor: "rgba(165, 173, 186, 0.7)",
          },
          "::-webkit-scrollbar-track": {
            borderRadius: 4,
            backgroundColor: "rgba(165, 173, 186, 0.2)",
            marginLeft: 182.5,
            marginRight: 182.5,
          },
        }}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      {...props}
      css={{
        display: "flex",
      }}
    >
      {children}
    </div>
  );
}
