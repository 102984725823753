import { useIntl } from "react-intl";

export default function useIntlValidationMessages() {
  const intl = useIntl();

  return {
    cantBeBlank: intl.formatMessage({
      id: "Bom3o3",
      defaultMessage: "Can't be blank",
    }),
    invalidEmailAddress: intl.formatMessage({
      id: "5WgK3Y",
      defaultMessage: "Invalid email address",
    }),
    invalidUrl: intl.formatMessage({
      id: "SASA4f",
      defaultMessage: "Invalid URL",
    }),
    invalidYear: intl.formatMessage({
      id: "19pVnU",
      defaultMessage: "Invalid Year",
    }),
    maxCharacters: (count: number) =>
      intl.formatMessage(
        {
          id: "L28iHN",
          defaultMessage: "Maximum of {count, plural, one {# character} other {# characters}}",
        },
        { count },
      ),
    minCharacters: (count: number) =>
      intl.formatMessage(
        {
          id: "cBu7Hw",
          defaultMessage: "Minimum of {count, plural, one {# character} other {# characters}}",
        },
        { count },
      ),
    selectAMinOfXitems: (count: number) =>
      intl.formatMessage(
        {
          id: "rDx1mj",
          defaultMessage: "Please select at least {count, plural, one {# item} other {# items}}",
        },
        { count },
      ),
    selectUpToItems: (count: number) =>
      intl.formatMessage(
        {
          id: "Lsh9I0",
          defaultMessage: "Please select up to {count, plural, one {# item} other {# items}}",
        },
        { count },
      ),
    firstNameIsRequired: intl.formatMessage({
      id: "zFG+sR",
      defaultMessage: "First Name is required",
    }),
    lastNameIsRequired: intl.formatMessage({
      id: "gmqce2",
      defaultMessage: "Last Name is required",
    }),
    addressLine1: intl.formatMessage({
      id: "s1yRvp",
      defaultMessage: "Address Line 1 is required",
    }),
    city: intl.formatMessage({
      id: "W435jR",
      defaultMessage: "City is required",
    }),
    postalCode: intl.formatMessage({
      id: "eI/SH6",
      defaultMessage: "Postal Code is required",
    }),
    country: intl.formatMessage({
      id: "jlqOPq",
      defaultMessage: "Country is required",
    }),
    state: intl.formatMessage({
      id: "Lmlys/",
      defaultMessage: "State is required",
    }),
    region: intl.formatMessage({
      id: "q5Y40T",
      defaultMessage: "Region is required",
    }),
    plan: intl.formatMessage({
      id: "8c4UnH",
      defaultMessage: "Please select a plan",
    }),
  };
}
