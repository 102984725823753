import React, { ReactNode, ReactElement, FunctionComponent } from "react";
import ReactDotdotdot from "react-dotdotdot";

interface IUnsafeLineClampProps {
  clamp: number;
  children: ReactElement | null;
}

interface IDotdotdotProps {
  clamp: number;
  children: ReactNode;
}

const Dotdotdot: FunctionComponent<IDotdotdotProps> = ({ clamp, children }): ReactElement => {
  return (
    <UnsafeLineClamp clamp={clamp}>
      <ReactDotdotdot clamp={clamp}>{children}</ReactDotdotdot>
    </UnsafeLineClamp>
  );
};

function UnsafeLineClamp({ clamp, children }: IUnsafeLineClampProps): ReactElement {
  return (
    <div
      css={{
        lineClamp: clamp,
        WebkitLineClamp: clamp,
        WebkitBoxOrient: "vertical",
        display: "-webkit-box",
        overflow: "hidden",
      }}
    >
      {children}
    </div>
  );
}

export default Dotdotdot;
