import * as palette from "@govlaunch/palette";

import React, { ReactElement } from "react";

import { CSSObject } from "@emotion/react";

interface IFieldValidationErrorProps {
  meta: Partial<{
    touched: boolean;
    error: any;
    submitError: any;
  }>;
  css?: CSSObject;
}

export default function FieldValidationError({ meta, css, ...props }: IFieldValidationErrorProps): ReactElement | null {
  if (meta.touched && (meta.error || meta.submitError)) {
    return (
      <div
        css={{
          marginLeft: "auto",
          color: palette.red,
          textTransform: "uppercase",
          fontSize: 12,
          ...css,
        }}
        {...props}
      >
        {meta.error || meta.submitError}
      </div>
    );
  }

  return null;
}
