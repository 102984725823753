export default function prefixWithHttpIfNecessary(withoutHttp?: string | null) {
  if (!withoutHttp) {
    return null;
  }

  if (withoutHttp.trim().length === 0) {
    return null;
  }

  if (withoutHttp.startsWith("http")) {
    return withoutHttp;
  }

  return `http://${withoutHttp}`;
}
