import React from "react";

export default function MakersIcon(props: React.SVGProps<any>) {
  return (
    <svg viewBox="0 0 24 24" version="1.1" {...props}>
      <defs>
        <linearGradient x1="12.5656436%" y1="11.8380996%" x2="90.0806178%" y2="100%" id="linearGradient-1">
          <stop stopColor="#55A2FA" offset="0%" />
          <stop stopColor="#B4EC51" offset="100%" />
        </linearGradient>
      </defs>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Makers">
          <g id="iconfinder_m-letter-uppercase-text-round-latin-keyboard_1585150">
            <path
              d="M12,0 C18.6190476,0 24,5.38095238 24,12 C24,18.6190476 18.6190476,24 12,24 C5.38095238,24 0,18.6190476 0,12 C0,5.38095238 5.38095238,0 12,0 Z"
              id="Path-2"
              fill="url(#linearGradient-1)"
            />
            <polygon
              id="Path"
              fill="#FFFFFF"
              fillRule="nonzero"
              points="15.0116959 10.3529412 12.6140351 15.2941176 11.4444444 15.2941176 9.04678363 10.3529412 9.04678363 16 7 16 7 7 9.69005848 7 11.9707602 11.9411765 14.3099415 7 17 7 17 16 15.0116959 16"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
